<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Dashboard"/>
      </v-toolbar> 
      <v-divider></v-divider>
      <div class="content-wrap">
          <v-row>
              <v-col cols="12">
                    <highcharts :options="chartOptionsNoRider" ref="chartNoRider"></highcharts>
              </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
                <v-select
                    :items="op.hook_type"
                    v-model="filter.hook_type"
                    label="Hook Type"
                    item-text="txt"
                    item-value="val"
                > </v-select>
            </v-col>
            <v-col cols="2">
                <v-select
                    :items="op.brand"
                    v-model="filter.brand"
                    label="Brand"
                    item-text="txt"
                    item-value="val"
                > </v-select>
            </v-col>
            <v-col cols="3">
                <v-select
                    :items="op.province"
                    v-model="filter.province"
                    label="Province"
                    item-text="txt"
                    item-value="val"
                > </v-select>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    v-model="filter.keyword"
                    append-icon="mdi-magnify"
                    label="Order No / Store"
                    single-line
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="1">
                <v-btn @click="exportDashboard();" class="primary mt-3" small>
                    Export
                </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="filterOrders"
            :items-per-page="100"
            :footer-props="footerProps"
            class="elevation-1"
            :sort-by="['diff']"
            >
                <template v-slot:item.created_at="{ item }">
                    <span>{{ moment(item.created_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
                </template>

                <template v-slot:item.diff="{ item }">
                    <span>{{ formatDiff(item.diff) }}</span>
                </template>

                <template v-slot:item.store_detail="{ item }">
                    <div>{{ item.store_detail }}</div>
                    <div>{{ item.delivery_zone }}</div>
                </template>

          </v-data-table>

      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, onMounted } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import Highcharts from 'highcharts';
import {Chart} from 'highcharts-vue'
import moment from 'moment';
import lodash from 'lodash';


const chartNoRider_span = 60;
let categories = [];
for (let i = 0 ; i < chartNoRider_span ; i++)
    categories.push(i+1);

export default {
    name: 'dashboard',
    watch : {
        /*
        filter : {
            deep: true,
            handler() {
                this.filterOrders();
            }

        }
        */
    },
    data() {


        return {
            rb_stores : null,
            search : '',
            footerProps: {'items-per-page-options': [50, 100, 500]},
            updateTimer : null,
            headers: [
                    { text: 'Order No', value: 'order_no' },
                    { text: 'Hook Type', value: 'hook_type' },
                    { text: 'Created At', value: 'created_at' },
                    { text: 'Delay', value: 'diff' },
                    { text: 'Brand', value: 'brand_alias' },
                    { text: 'Province', value: 'store.store_address.region' },
                    { text: 'Alias ID', value: 'store.store_alias_id' },
                    { text: 'Store Name', value: 'store.name_th' },
                    { text: 'Store Phone', value: 'store.phone_store' },
                    { text: 'Manager Phone', value: 'store.phone_manager' },
                    { text: 'Delivery Zone', value: 'store.delivery_zone' },

            ],
            op : { 
                hook_type : [
                    { txt : "-", val : null},
                    { txt : "NO WEBHOOK", val : "" },
                    { txt : "ORDERCREATIONNOTIFICATION", val : "ORDERCREATIONNOTIFICATION" },
                    { txt : "ORDERALLOCATIONSTOP", val : "ORDERALLOCATIONSTOP" },
                ],
                brand : [],
                province : []
            },
            filter : {
                hook_type : null,
                brand : null,
                province : null,
                keyword : ""
            },
            orders : [],
            chartNoRider: Highcharts,

            chartOptionsNoRider: {
                chart: {
                    type: 'line'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: 'Minute after order placed'
                    }
                },
                yAxis: {
                    title: {
                        text: 'Orders'
                    },
                    min : 0,
                    minRange: 10
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                },
                series: [
                    {
                        name: 'Total',
                        data: []
                    },
                    {
                        name: 'No LN webhook',
                        data: []
                    },
                    {
                        name: 'Order Creation',
                        data: []
                    },
                    {
                        name: 'Order Allocation Stop',
                        data: []
                    }


                ]
            }

        }
    },
    components: {
        pageHeading,
        highcharts: Chart
    },
    methods: {
        ...mapActions(['getNoRiderOrdersByTime', 'getAllRedbookStore', 'genExcelFile', 'downloadFromBase64']),
        formatDiff: function(diff) {
            return (Math.abs(diff) < 60) ? diff + " secs" : Math.floor(diff/60) + " mins";
        },
        prepareStoreData : async function () {
            this.rb_stores = lodash.keyBy( await this.getAllRedbookStore(), 'store_uid' )
        },
        getNoRiderChartData : async function () {

            console.log("Get chart data at", moment().format("YYYY-MM-DD HH:mm:ss"));
            let data = await this.getNoRiderOrdersByTime({interval:1, span:chartNoRider_span});
            let local_rb_store = this.rb_stores;

            let op_brand = {};
            let op_province = {};
            this.orders = lodash.map(data.orders, function(item) {
                
                item.store_detail = "";
                item.delivery_zone = "";

                if (item.brand_alias && !op_brand[item.brand_alias])
                    op_brand[item.brand_alias] = true;
                
                if (item.store && item.store.store_address && !op_province[item.brand_alias])
                    op_province[item.store.store_address.region] = true;

                if (local_rb_store != null && local_rb_store[item.store_uid])
                {
                    //item.store_detail = item.store_uid + " (M. Tel:: " + local_rb_store[item.store_uid].phone_manager + ", S.Tel" + local_rb_store[item.store_uid].phone_store + ")";
                    item.delivery_zone = local_rb_store[item.store_uid].delivery_zone
                }
                return item;
            })

            this.op.brand = [{ txt : "-", val : null}, ...lodash.map(op_brand, function(item, key) {
                return { txt : key, val : key};
            })];

            this.op.province = [{ txt : "-", val : null}, ...lodash.map(op_province, function(item, key) {
                return { txt : key, val : key};
            })];


            let chart_data = data.chart_data;

            console.log(chart_data);

            this.chartOptionsNoRider.title.text = "Number of orders that no rider received " + moment().format("YYYY-MM-DD HH:mm:ss");
            

            this.chartOptionsNoRider.series[0].data = chart_data["total"];
            this.chartOptionsNoRider.series[1].data = chart_data["no_webhook"];
            this.chartOptionsNoRider.series[2].data = chart_data["order_creation"];
            this.chartOptionsNoRider.series[3].data = chart_data["order_allocation_stop"];
        },
        exportDashboard : async function() {
                    
            let col_title =  lodash.reduce(this.headers, function(result, item) { 
                result[item.value] = item.text;
                return result;
            }, {});

            let col_width =  lodash.reduce(this.headers, function(result, item) { 
                result[item.value] = item.width ? item.width/10 : 15;
                return result;
            }, {});


            let filter_orders = this.orders;
            let local_filter = this.filter;

            if (this.filter.hook_type != null)
            {
                filter_orders = lodash.filter(filter_orders, function(item){ 
                    return (item.hook_type || "") == local_filter.hook_type;
                });
            }
            if (this.filter.brand)
            {
                filter_orders = lodash.filter(filter_orders, function(item){ 
                    return item.brand_alias == local_filter.brand;
                });
            }
            if (this.filter.province)
            {
                let local_filter = this.filter;
                filter_orders = lodash.filter(filter_orders, function(item){ 
                    let store = item.store;
                    if (!store)
                        return false;
                    return (store.store_address.region || "") == local_filter.province;
                });
            }
            if (this.filter.hook_type != null)
            {
                let local_filter = this.filter;
                filter_orders = lodash.filter(filter_orders, function(item){ 
                    return (item.hook_type || "") == local_filter.hook_type;
                });
            }
            if (this.filter.keyword)
            {
                filter_orders = lodash.filter(filter_orders, function(item){ 
                    if (item.order_no.indexOf(local_filter.keyword) >= 0)
                        return true;
                    if (item.store_alias_id.indexOf(local_filter.keyword) >= 0)
                        return true;
                    let store = item.store;
                    if (!store)
                        return false;
                    if (store.name_th && store.name_th.indexOf(local_filter.keyword) >= 0)
                        return true;
                    if (store.phone_manager && store.phone_manager.indexOf(local_filter.keyword) >= 0)
                        return true;
                    if (store.phone_store && store.phone_store.indexOf(local_filter.keyword) >= 0)
                        return true;
                    if (store.delivery_zone && store.delivery_zone.indexOf(local_filter.keyword) >= 0)
                        return true;
                    return false;                 
                });
            }

            filter_orders = lodash.map(filter_orders,function(item){
                return {
                    ...item,
                    "store.store_address.region" : item.store ? item.store.store_address.region : "-",
                    "store.store_alias_id" : item.store ? item.store.store_alias_id : "-",
                    "store.name_th" : item.store ? item.store.name_th : "-",
                    "store.phone_store" : item.store ? item.store.phone_store : "-",
                    "store.phone_manager" : item.store ? item.store.phone_manager : "-",
                    "store.delivery_zone" : item.store ? item.store.delivery_zone : "-",
                }
                   
            })
            let excel_b64 = await this.genExcelFile({ 
                inp_data_json : filter_orders,
                inp_header : col_title,
                col_width : col_width,
                sheet_name : "NoRiderOrders"
            });
            console.log(excel_b64);
            await this.downloadFromBase64({
                filename : "Dashboard.xlsx", 
                text_b64 : excel_b64,
                type : "application/vnd.ms-excel"
            });
        }
    },
    computed: {
        // ...mapGetters([''])
        filterOrders(){
            let filter_orders = this.orders;
            let local_filter = this.filter;

            if (this.filter.hook_type != null)
            {
                filter_orders = lodash.filter(filter_orders, function(item){ 
                    return (item.hook_type || "") == local_filter.hook_type;
                });
            }
            if (this.filter.brand)
            {
                filter_orders = lodash.filter(filter_orders, function(item){ 
                    return item.brand_alias == local_filter.brand;
                });
            }
            if (this.filter.province)
            {
                let local_filter = this.filter;
                filter_orders = lodash.filter(filter_orders, function(item){ 
                    let store = item.store;
                    if (!store)
                        return false;
                    return (store.store_address.region || "") == local_filter.province;
                });
            }
            if (this.filter.hook_type != null)
            {
                let local_filter = this.filter;
                filter_orders = lodash.filter(filter_orders, function(item){ 
                    return (item.hook_type || "") == local_filter.hook_type;
                });
            }
            if (this.filter.keyword)
            {
                filter_orders = lodash.filter(filter_orders, function(item){ 
                    if (item.order_no.indexOf(local_filter.keyword) >= 0)
                        return true;
                    if (item.store_alias_id.indexOf(local_filter.keyword) >= 0)
                        return true;
                    let store = item.store;
                    if (!store)
                        return false;
                    if (store.name_th && store.name_th.indexOf(local_filter.keyword) >= 0)
                        return true;
                    if (store.phone_manager && store.phone_manager.indexOf(local_filter.keyword) >= 0)
                        return true;
                    if (store.phone_store && store.phone_store.indexOf(local_filter.keyword) >= 0)
                        return true;
                    if (store.delivery_zone && store.delivery_zone.indexOf(local_filter.keyword) >= 0)
                        return true;
                    return false;                 
                });
            }
            return filter_orders;
        }
    },
    async beforeMount() {
        await this.prepareStoreData();
        await this.getNoRiderChartData();
    },
    mounted() {
        this.updateTimer = setInterval(this.getNoRiderChartData, 60000);

    },
    beforeDestroy() {
        console.log("clear interval")
        clearInterval(this.updateTimer);
    },


};
</script>